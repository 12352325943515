@import "../../../styles/abstracts/animations";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/abstracts/variables";

.home-carousel {
  &__slide {
    position: relative;
    width: 100%;
    aspect-ratio: 2.4 / 1;
    overflow: hidden;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    // @include respond(bp600) {
    //   aspect-ratio: 1.42 / 1;
    // }
  }

  &__slide-img {
    visibility: hidden;
  }
}
